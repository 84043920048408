import gql from 'graphql-tag';
import { UserFragment } from '@/graphql/fragments/user';

export const Me = gql`
	query Me {
		me {
      ...UserFields
    }
  }
  ${UserFragment}
`
import gql from 'graphql-tag';

export const JobStopFragment = gql`
  fragment JobStopFields on JobStop {
    id
    stop_point
    stop_latitude
    stop_longitude
    short_address
    order
    company_name
  }
`;

<template>
  <ion-app>
    <base-card v-if="maintenance" class="event__card-maintenance">
      <events-list-item :event="maintenance" @on-close="closeMaintenanceMessage" is-close-button-required/>
    </base-card>
    <base-card v-if="event" class="event__card" @click="redirectToEntityPage(event)">
      <events-list-item :event="event" />
    </base-card>
    <router-view />
    <location-access-modal v-if="getIsLocationPermissionCompatibleRoute" />
    <payment-loader v-if="isPaymentLoaderVisible" />
  </ion-app>
</template>

<script setup lang="ts">
import { IonApp, isPlatform } from '@ionic/vue';
import { computed, onMounted, ref, watch } from "vue";
import { App, URLOpenListenerEvent } from '@capacitor/app';
import router from "@/router";
import { SplashScreen } from "@capacitor/splash-screen";
import { initializeApollo, receivedEvent, receivedMaintenanceEnd, receivedMaintenanceStart } from "@/apollo"
import BaseCard from '@/modules/General/components/base/BaseCard.vue';
import EventsListItem from '@/modules/Notifications/components/EventsListItem.vue';
import { redirectToEntityPage } from '@/helpers/redirectToPageByEntity';
import { RouteNamesEnum } from "@/const/routes";
import LocationAccessModal from '@/modules/General/components/LocationAccessModal.vue';
import { useRoute } from 'vue-router';
import StorageService from './helpers/storageService';
import { isAbleToDo } from './helpers/isAbleToDo';
import { OnFlightTypeUpdates, PaidSubscriptionStatus, Plan, ProfileSettingsEnum } from './gql/graphql';
import { handleFirebaseUserTracking } from './helpers/handleFirebaseUserTracking';
import { useUserStore } from './modules/General/store/userStore';
import { isEmpty, isEqual } from 'lodash';
import { Browser } from '@capacitor/browser';
import { setFirebaseUserOnline } from './helpers/toggleFirebaseUserIsOnline';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { Loader } from '@googlemaps/js-api-loader';
import { setGoogleObj, googleObj } from './helpers/setGoogleObj';
import { subscribeWithInterval, unsubscribeFromAllSubscriptions } from './helpers/subscribeToOnFlightUpdates';
import PaymentLoader from '@/modules/Subscription/components/PaymentLoader.vue'
import { paymentLoader } from './helpers/setPaymentLoader';
import { isMobile } from "./helpers/isMobile";
import { Me } from './graphql/queries/me';
import { restorePurchases } from './helpers/cordova-plugin-purchase';

const isPaymentLoaderVisible = ref(false)
const userStore = useUserStore();
const updateUserInUserStore = () => {
  const user = StorageService.getUser();
  if(!isEqual(user, userStore?.user)) {
    userStore.updateUserStore(StorageService.getUser())
  }
}
setInterval(() => updateUserInUserStore(), 1000);


const user = computed(() => useUserStore()?.user);

const google = ref();
onMounted(async () => {
  App.addListener('appStateChange', appStateChangeCallback);
  await SplashScreen.hide();
});

const isLocationPermissionCompatibleRoute = ref(false);

const route = useRoute();

watch(() => route, () => {
  if(route.meta.requiresAuth) {
    isLocationPermissionCompatibleRoute.value = true
  }
}, {deep: true, immediate: true})

const getIsLocationPermissionCompatibleRoute = computed(() => isLocationPermissionCompatibleRoute.value)

const event = ref();
const maintenance = ref();

watch(() => receivedEvent, () => {
  if(receivedEvent.value) {
    event.value = receivedEvent.value;
    setTimeout(() => {event.value = null}, 5000)
  }
}, {deep: true, immediate: true});

watch(() => paymentLoader, () => {
  if(paymentLoader) {
    isPaymentLoaderVisible.value = paymentLoader.value;
  }
}, {deep: true, immediate: true});

const closeMaintenanceMessage = () => maintenance.value = null;

watch(() => receivedMaintenanceStart, () => {
  if(receivedMaintenanceStart.value) {
    maintenance.value = receivedMaintenanceStart.value;
  }
}, {deep: true, immediate: true});

watch(() => receivedMaintenanceEnd, () => {
  if(receivedMaintenanceEnd.value) {
    maintenance.value = null;
  }
}, {deep: true, immediate: true});

App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
  if(event.url.includes('/impersonate/')) {
    router.push({ name: RouteNamesEnum.Impersonate, params: { token: event.url.split('/impersonate/').pop() }});
  } else if(event.url.includes('/create-password?')) {
    router.push({ name: RouteNamesEnum.CreatePassword, query: { token: event.url.split('/create-password?').pop() }});
  } else if(event.url.includes('/update-password?')) {
    router.push({ name: RouteNamesEnum.UpdatePassword, query: { token: event.url.split('/update-password?').pop() }});
  }

  // if(event.url.includes('/create-password')) {
  //   await Browser.open({ url: event.url });
  // }

});

const appStateChangeCallback = async ({ isActive }: { isActive: boolean }) => {
  if(isMobile.value) {
    const newVersionAvailable = await isUpdateAvailable();
    if(newVersionAvailable) {
      event.value = {type: OnFlightTypeUpdates.AppUpdate, name: 'SDCN update alert!', date: '', description: 'Keep your mobile app running smoothly with the latest version. For optimal performance, remember to clear your browser cache.'};
    }
  }
  if(!isEmpty(user.value)) {
    unsubscribeFromAllSubscriptions();
    subscribeWithInterval(user.value);
    if (isActive) {
      await initializeApollo().query({
        query: Me,
        variables: {id: user.value?.id}
      }).then((response) => {
        if ((isAbleToDo(user.value, ProfileSettingsEnum.Tracking) || isAbleToDo(user.value, ProfileSettingsEnum.JobNotificationTracking)) && !StorageService.getItem('isPositionWatching')) {
          handleFirebaseUserTracking(user.value);
        }
        setFirebaseUserOnline(user.value, true)

        // restorePurchases();

        if((isPlatform('ios') ? response?.data?.me?.team?.plan?.verified : true) && (!StorageService.getUser()?.team?.is_active_subscriber || (StorageService.getUser()?.team?.is_active_subscriber && response.data?.me?.team?.allPlans?.some((p: Plan) => p?.status === PaidSubscriptionStatus.Future))) && (route.name === RouteNamesEnum.Subscription || route.name === RouteNamesEnum.SubscriptionEdit || route.name === RouteNamesEnum.AddCompanySubscription)) {
          router.push({name: RouteNamesEnum.Notifications});
        }
        StorageService.setUser(response?.data?.me)
      })
    } else {
      setFirebaseUserOnline(user.value, false)
    }
  }
};

const isUpdateAvailable = async () => {
  const result = await AppUpdate.getAppUpdateInfo();
  return +result?.updateAvailability === 2
};

const isGoogleSet = ref(false);

watch(() => googleObj, async () => {
  if (!googleObj.value && !isGoogleSet.value) {
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
      libraries: ['places', 'geometry'],
      language: 'en',
    });
    await loader.importLibrary('marker');
    await loader.importLibrary('maps');

    setGoogleObj(window.google);
    isGoogleSet.value = true;
    
    google.value = googleObj.value;
    
    new google.value.maps.places.AutocompleteService();
    new google.value.maps.Geocoder();
    new google.value.maps.marker.AdvancedMarkerElement();
  }
}, {deep: true, immediate: true})

</script>

<style scoped lang="scss">
.event {
  &__card, &__card-maintenance {
    margin: 48px 16px 16px;
    position: absolute;
    width: -webkit-fill-available;
    width: -moz-available;
    z-index: 2;
    
    @media only screen and (min-width: 1023px) {
      margin-top: 16px;
    }
  }

  &__card-maintenance {
    bottom: 0;
    right: 0;

    @media only screen and (min-width: 1023px) {
      max-width: calc(100% - 280px - 16px - 16px - 124px);
      right: 124px;
    }
  }

  &__date {
    color: var(--ion-color-dark);
    font-family: var(--ion-font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.3px;
    text-transform: capitalize;
    margin-bottom: 15px;
  }
}
</style>
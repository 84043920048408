import gql from 'graphql-tag';
import { PlanFragment } from '../fragments/plan';

export const MobilePlans = gql`
  query MobilePlans {
    mobilePlans {
      ...PlanFields
    }
  }
  ${PlanFragment}
`
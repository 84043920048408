import gql from 'graphql-tag';

export const JobStopDestinationFragment = gql`
  fragment JobStopDestinationFields on JobStop {
    destination_asap
    destination_date
    destination_date_end
    destination_met
    flexible_destination
    notes
  }
`;

import { loadStripe } from '@stripe/stripe-js';
import { ref } from 'vue';
import { isMobile } from './isMobile';

const stripe = ref();

const setupStripe = async () => {
  stripe.value = await loadStripe(process.env.VUE_APP_STRIPE_PK);
  return stripe.value;
}

const getStripe = async () => {
  if(!stripe.value && !isMobile.value) {
    stripe.value = await setupStripe();
  }
  return stripe;
}

export { stripe, setupStripe, getStripe };
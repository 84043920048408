import { doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { firebaseApp } from '@/firebaseConfig';
import { User } from '@/gql/graphql';

const setFirebaseUserOnline = async (user: User, isOnline: boolean) => {
  if(!user?.is_impersonated) {
    try {
      const firestore = getFirestore(firebaseApp);
      const userDbRef = doc(firestore, 'users', user?.id);
      await getDoc(userDbRef).then(async (docSnapshot) => {
        if (docSnapshot.exists()) {
          await updateDoc(userDbRef, {
            isOnline
          })
        } else {
          await setDoc(userDbRef, {
            isOnline,
            updated_at: new Date().valueOf(),
            id: user?.id
          });
        }
      })
    } catch (error) {
      if(error?.toString().includes('offline')) {
        setTimeout(() => {
          setFirebaseUserOnline(user, isOnline);
        }, 10000);
      }
    }
  }
}

export { setFirebaseUserOnline };
import gql from 'graphql-tag';

export const PlanFragment = gql`
  fragment PlanFields on Plan {
    id
    name
    description
    cost
    stripe_plan
    active
    max_users
    interval
    interval_count
    apple_mobile_plan_id
    google_mobile_plan_id
    verified
    status
    price
  }
`;

import gql from 'graphql-tag';
import { JobPickupDestinationFragment } from '../fragments/jobPickupDestination';
import { JobStopFragment } from '../fragments/jobStop';
import { JobStopDestinationFragment } from '../fragments/jobStopDestination';

export const Job = gql`
	query Job($id: ID!) {
		job(id: $id) {
      id
      accept_online
      accept_email
      accept_phone
      phone
      status
      distance
      duration
      height
      weight
      ...JobPickupDestinationFields
      bid_amount
      bid_user {
        id
        name_first
        name_last
        email
        phone
        avatar_url
        team {
          invoice_including_vat
          vat_number
          invoice_payment_terms
          company_name
          id
        }
      }
      driver_route
      vehicle {
        id
        name
        icon_url
        size
      }
      payment_terms
      invoices {
        id
        to_company
        from_company
      }
      details
      formatted_details
      additional_options
      created_at
      jobStops {
        ...JobStopFields
        ...JobStopDestinationFields
        util {
          is_drop_off
          date
          pod_not_required
          recipient
          reason
          is_electronic_pod
          details
        }
        uploads {
          upload_link
          is_electronic_pod
        }
      }
      user {
        id
        email
        phone
        name_first
        name_last
        team {
          company_name
          id
        }
      }
      customer_job_reference_number
      expiry_time
      bids {
        id
        amount
        status
        user {
          id
        }
        rejection_reason
        rejection_reason_custom
        has_more_bids
      }
      route_date
      collection_date
      delivery_start
      delivery_end
      pod {
        id
        delivery_date
        recipient
        no_pod_reason
        is_electronic_pod
        details
        uploads {
          id
          upload_link
          is_electronic_pod
        }
      }
      gob {
        id
        board_route_date
        uploads {
          id
          upload_link
        }
      }
      has_unseen_messages
      has_conversation
      private_notes
      invoice_has_vat
      job_status_transition_metadatas {
        status
        data_dto {
          ... on ArrivedAtCollectionDTO {
            goods_collected_success
            date_and_time
            arrived_reason: reason
            comment
            wait_at_collection_point
            additional_charges_agreed
            agreed_price
          }
          ... on CanceledDTO {
            canceled_reason: reason
            comment
            additional_charges_agreed
            agreed_price
            date_and_time
          }
        }
      }
    }
  }
  ${JobStopFragment}
  ${JobStopDestinationFragment}
  ${JobPickupDestinationFragment}
`
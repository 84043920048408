import gql from 'graphql-tag';

export const JobPickupDestinationFragment = gql`
  fragment JobPickupDestinationFields on Job {
    pickup_point
    pickup_longitude
    pickup_latitude
    pickup_date
    pickup_date_end
    flexible_pickup
    pickup_asap
    pickup_company_name
    pickup_short_address
    destination_point
    destination_longitude
    destination_latitude
    destination_date
    destination_date_end
    flexible_destination
    destination_asap
    destination_short_address
    destination_company_name
  }
`;

import gql from 'graphql-tag';
import { UserFragment } from '@/graphql/fragments/user';

export const UpdateFleet = gql`
  mutation UpdateFleet($input: UpdateFleetInput!) {
    updateFleet(input: $input) {
      id
      name
      default_availability
      temporary_availability
      tracking
      user {
        ...UserFields
      }
      vehicle {
        id
      }
      until
      status_text
      status_until
      destination_address
      destination_lat
      destination_lng
    }
  }
  ${UserFragment}
`

import { doc, GeoPoint, updateDoc, setDoc, getDoc } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { firebaseApp } from '@/firebaseConfig';
import { getCurrentPosition } from "@/helpers/getCurrentPosition";
import StorageService from "@/helpers/storageService";
import { clearUpdatePosition } from './tracking';
import { runIntervalForSystemPermissionCheck } from '@/ts/geolocationUpdateStarter';
import { getIsWrongPermissions } from './requestLocationPermissions';
import { isAbleToDo } from './isAbleToDo';
import { ProfileSettingsEnum, User } from '@/gql/graphql';
import { useToast } from 'vue-toastification';
import haversineDistance from './haversine';

const firestore = getFirestore(firebaseApp);

const startupTrackingUpdate = async (user: User) => {
  if(!user?.is_impersonated) {
    try {
      const wrongPermission = await getIsWrongPermissions();
      if (user?.id && isAbleToDo(user, ProfileSettingsEnum.Tracking) && !wrongPermission) {
        const userDbRef = doc(firestore, 'users', user?.id);
        await getDoc(userDbRef).then(async (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            if(data.active === false) {
              await updateDoc(userDbRef, {
                active: true,
              })
            }
          } else {
            doUpdateFirebaseUser(true, user)
          }
        })
      }
    } catch (error) {
      if(error?.toString().includes('offline')) {
        setTimeout(() => {
          startupTrackingUpdate(user);
        }, 10000);
      }
    }
  }
}

const updateDbUserLocation = async (user: User, active: boolean) => {
  if(!user?.is_impersonated) {
    const wrongPermission = await getIsWrongPermissions();
    if (
      !isAbleToDo(user, ProfileSettingsEnum.Tracking)
      || wrongPermission
    ) {
      clearUpdatePosition(user);
      runIntervalForSystemPermissionCheck();
      return;
    }
    const previousPosition = { coords: StorageService.getItem('currentPosition') ? StorageService.getItem('currentPosition') : {} };
    const position: any = await getCurrentPosition();
    
    if ((previousPosition.coords?.latitude && haversineDistance(previousPosition.coords, position.coords) > 3) || !previousPosition.coords?.latitude) {
      StorageService.setItem('currentPosition', { latitude: position.coords.latitude, longitude: position.coords.longitude });
      doUpdateFirebaseUser(active, user, position);
    }
  }
};

const doUpdateFirebaseUser = async (active: boolean, user: User, position?: any) => {
  if(!user?.is_impersonated) {
    const userDbRef = doc(firestore, 'users', user?.id);
    try {
      if (!position) {
        position = await getCurrentPosition();
      }
    } catch (error) {
      const toast = useToast();
      toast.error('Enable location permission for your device to start update geo position');
      return;
    }

    try {
      await updateDoc(userDbRef, {
        active,
        location: new GeoPoint(position.coords.latitude, position.coords.longitude),
        updated_at: new Date().valueOf(),
      })
        .then(() => console.log('Updating location success'));
    } catch (error: any) {
      if (error.toString().includes('No document to update')) {
        await setDoc(userDbRef, {
          active,
          location: new GeoPoint(position.coords.latitude, position.coords.longitude),
          updated_at: new Date().valueOf(),
          id: user?.id
        });
      } else {
        console.error('Error updating location:', error);
      }
    }
  }
}

const deactivateFirebaseUser = async (user: User) => {
  if(!user?.is_impersonated) {
    try {
      const userDbRef = doc(firestore, 'users', user?.id);
      await getDoc(userDbRef).then(async (docSnapshot) => {
        if (docSnapshot.exists()) {
          await updateDoc(userDbRef, {
            active: false,
          })
            .then(() => console.log('User deactivated successfully'));
        } else {
          await setDoc(userDbRef, {
            active: false,
            id: user?.id
          });
        }
      })
    } catch (error) {
      if(error?.toString().includes('offline')) {
        setTimeout(() => {
          deactivateFirebaseUser(user);
        }, 10000);
      }
    }
  }
}

export { doUpdateFirebaseUser, updateDbUserLocation, deactivateFirebaseUser, startupTrackingUpdate };
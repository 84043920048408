export enum EventNamesEnum {
  OnHandleSubmit = 'on-handle-submit',
  OnHandlePreview = 'on-handle-preview',
  OnDeleteSavedLoad = 'on-delete-saved-load',
  OnStopNotes = 'on-stop-notes',
  OnDelete = 'on-delete',
  OnEditSavedLoad = 'on-edit-saved-load',
  OnOpenSavedLoad = 'on-open-saved-load',
  OnUpdateStatusCheck = 'on-update-status-check',
  OnUpdateRadius = 'on-update-radius',
  OnUpdateTimePeriod = 'on-update-time-period',
  OnUpdateDates = 'on-update-dates',
  OnUpdatePage = 'on-update-page',
  OnRepost = 'on-repost',
  OnEdit = 'on-edit',
  OnEditStops = 'on-edit-stops',
  OnCancel = 'on-cancel',
  OnOnRouteToCollect = 'on-route-to-collect',
  OnArrivedAtCollection = 'on-arrived-at-collection',
  OnOnRouteToDeliver = 'on-route-to-deliver',
  OnArrivedAtDestination = 'on-arrived-at-destination',
  OnOnRouteToReturn = 'on-route-to-return',
  OnReturned = 'on-returned',
  OnReviewBids = 'on-review-bids',
  OnRetractBid = 'on-retract-bid',
  OnBidRejected = 'on-bid-rejected',
  OnSubmitBid = 'on-submit-bid',
  OnManageBids = 'on-manage-bids',
  OnOpenConfirmModal = 'on-open-confirm-modal',
  OnOpenRejectModal = 'on-open-reject-modal',
  OnGoodsOnBoard = 'on-goods-on-board',
  OnDelivered = 'on-delivered',
  OnViewPod = 'on-view-pod',
  OnViewPor = 'on-view-por',
  OnRaiseInvoice = 'on-raise-invoice',
  OnViewInvoice = 'on-view-invoice',
  OnMarkAsPaid = 'on-mark-as-paid',
  OnCompleteJob = 'on-complete-job',
  OnFeedback = 'on-feedback',
  OnCheck = 'on-check',
  OnView = 'on-view',
  OnContactAction = 'on-contact-action',
  OnChat = 'on-chat',
  OnEditPrivateNotes = 'on-edit-private-notes',
  IsEdit = 'is-edit',
  OnDriverChange = 'on-driver-change',
  OnRedirectToBidHistory = 'on-redirect-to-bid-history',
  OnSave = 'on-save',
  OnClose = 'on-close',
  OnClick = 'on-click',
  OnRangeClick = 'on-range-click',
  OnClear = 'on-clear',
  OnChange = 'on-change',
  OnConfirm = 'on-confirm',
  OnMore = 'on-more',
  OnCreatePaymentMethod = 'on-create-payment-method',
  OnCancelIncompletePayment = 'on-cancel-incomplete-payment',
  OnRefetchDirectories = 'on-refetch-directories',
  OnSelect = 'on-select',
  OnSelectedItem = 'on-selected-item',
  OnNavigate = 'on-navigate',
  OnOpenFilters = 'on-open-filters',
  OnOpenSorting = 'on-open-sorting',
  OnClearFilters = 'on-clear-filters',
  OnPlaceChangedFromGoogle = 'on-place-changed-from-google',
  OnPlaceChangedFromAddressBook = 'on-place-changed-from-address-book',
  OnDropdownUpdate = 'on-dropdown-update',
  OnMoveToNextStep = 'on-move-to-next-step',
  OnUpdateModelValue = 'update:model-value',
  OnUpdateValue = 'update:value',
  OnSelectChange = 'on-select-change',
  OnStateSelect = 'on-state-select',
  OnPopupSubmit = 'on-popup-submit',
  OnUpdateAll = 'on-update-all',
  OnDownload = 'on-download',
  OnUpdateSearch = 'on-update-search',
  OnEditPrice = 'on-edit-price',
  OnPreview = 'on-preview'
}

import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { RouteNamesEnum } from '@/const/routes';
import { Logout } from '@/graphql/mutations/logout';
import { initializeApollo } from "@/apollo";
import { PaymentMethod, ProfileSettingsEnum, RegistrationProgress, SubscriptionStatuses, User } from '@/gql/graphql';
import { isAbleToDo } from '@/helpers/isAbleToDo';
import { checkLocationPermissions } from '@/helpers/requestLocationPermissions';
import StorageService from "@/helpers/storageService";
import { clearUpdatePosition } from '@/helpers/tracking';
import { subscribeWithInterval, unsubscribeFromAllSubscriptions } from '@/helpers/subscribeToOnFlightUpdates';
import { setupStripe } from '@/helpers/stripe';
import { isPlatform } from '@ionic/vue';
import { deactivateFirebaseUser } from '@/helpers/updateFirebaseUserLocation';
import { runIntervalForSystemPermissionCheck } from '@/ts/geolocationUpdateStarter';
import { ref } from 'vue';
import { handleFirebaseUserTracking } from '@/helpers/handleFirebaseUserTracking';
import { setFirebaseUserOnline } from '@/helpers/toggleFirebaseUserIsOnline';
import OneSignalHandler from '@/ts/onesignal';
import { isMobile } from '@/helpers/isMobile';
import { disableListeners } from '@/helpers/cordova-plugin-purchase';

const OneSignal = new OneSignalHandler();

const routes: Array<RouteRecordRaw> = [
  {
    name: RouteNamesEnum.NotFound,
    path: '/:pathMatch(.*)*',
    component: () => import('@/modules/General/pages/NotFoundPage.vue'),
  },
  {
    path: '/',
    redirect: '/login',
  },
  {
    name: RouteNamesEnum.Login,
    path: '/login',
    component: () => import('@/modules/Auth/pages/LoginPage.vue'),
  },
  {
    name: RouteNamesEnum.SignUp,
    path: '/sign-up',
    component: () => import('@/modules/Auth/pages/SignUpPage.vue'),
  },
  {
    name: RouteNamesEnum.Congratulations,
    path: '/congratulations',
    component: () => import('@/modules/Auth/pages/CongratulationsPage.vue'),
  },
  {
    name: RouteNamesEnum.CreatePassword,
    path: '/create-password',
    component: () => import('@/modules/Auth/pages/CreatePasswordPage.vue'),
  },
  {
    name: RouteNamesEnum.UpdatePassword,
    path: '/update-password',
    component: () => import('@/modules/Auth/pages/CreatePasswordPage.vue'),
  },
  {
    name: RouteNamesEnum.SelectRole,
    path: '/select-role',
    component: () => import('@/modules/Auth/pages/SelectRolePage.vue'),
  },
  {
    name: RouteNamesEnum.ForgotPassword,
    path: '/forgot-password',
    component: () => import('@/modules/Auth/pages/ForgotPasswordPage.vue'),
  },
  {
    name: RouteNamesEnum.Attention,
    path: '/attention',
    component: () => import('@/modules/Auth/pages/AttentionPage.vue'),
  },
  {
    name: RouteNamesEnum.DownloadAppPage,
    path: '/download-app',
    component: () => import('@/modules/Auth/pages/DownloadAppPage.vue'),
  },
  {
    name: RouteNamesEnum.ActionUnauthorized,
    path: '/action-unauthorized',
    meta: { requiresAuth: true },
    component: () => import('@/modules/General/pages/ActionUnauthorizedPage.vue'),
  },
  {
    name: RouteNamesEnum.Company,
    path: '/company',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Company/pages/CompanyPage.vue'),
  },
  {
    name: RouteNamesEnum.CompanyEmail,
    path: '/company-email',
    component: () => import('@/modules/Company/pages/CompanyEmailPage.vue'),
  },
  {
    name: RouteNamesEnum.CompanyContact,
    path: '/company-contact',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Company/pages/CompanyContactPage.vue'),
  },
  {
    name: RouteNamesEnum.AddCompanyInfo,
    path: '/add-company-info',
    component: () => import('@/modules/Company/pages/AddCompanyInfoPage.vue'),
  },
  {
    name: RouteNamesEnum.AddCompanySubscription,
    path: '/add-company-subscription',
    component: () =>
      import('@/modules/Company/pages/AddCompanySubscriptionPage.vue'),
  },
  {
    name: RouteNamesEnum.Subscription,
    path: '/subscription',
    component: () =>
      import('@/modules/Subscription/pages/SubscriptionPage.vue'),
  },
  {
    name: RouteNamesEnum.SubscriptionEdit,
    path: '/subscription-edit',
    component: () =>
      import('@/modules/Subscription/pages/SubscriptionEditPage.vue'),
  },
  {
    name: RouteNamesEnum.SubscriptionEditCards,
    path: '/subscription-edit-cards',
    component: () =>
      import('@/modules/Subscription/pages/SubscriptionEditCards.vue'),
  },
  {
    name: RouteNamesEnum.SubscriptionViewInvoices,
    path: '/subscription-view-invoices',
    meta: { requiresAuth: true },
    component: () =>
      import('@/modules/Subscription/pages/SubscriptionViewInvoices.vue'),
  },
  {
    name: RouteNamesEnum.SubscriptionAddCard,
    path: '/subscription-add-card',
    component: () =>
      import('@/modules/Subscription/pages/SubscriptionAddCard.vue'),
  },
  {
    name: RouteNamesEnum.CompanyInfo,
    path: '/company-info',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Company/pages/CompanyInfoPage.vue'),
  },
  {
    name: RouteNamesEnum.InvoiceAddress,
    path: '/invoice-address-page',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Company/pages/InvoiceAddressPage.vue'),
  },
  {
    name: RouteNamesEnum.InvoiceRecipient,
    path: '/invoice-recipient',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Company/pages/InvoiceRecipientPage.vue'),
  },
  {
    name: RouteNamesEnum.InvoiceDetails,
    path: '/invoice-details',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Company/pages/InvoiceDetailsPage.vue'),
  },
  {
    name: RouteNamesEnum.InvoiceLogo,
    path: '/invoice-logo',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Company/pages/InvoiceLogoPage.vue'),
  },
  {
    name: RouteNamesEnum.AddressBook,
    path: '/address-book',
    meta: { requiresAuth: true },
    component: () => import('@/modules/AddressBook/pages/AddressBookPage.vue'),
  },
  {
    name: RouteNamesEnum.AddContact,
    path: '/add-contact',
    meta: { requiresAuth: true },
    component: () => import('@/modules/AddressBook/pages/AddEditViewContactPage.vue'),
  },
  {
    name: RouteNamesEnum.EditContact,
    path: '/edit-contact/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/AddressBook/pages/AddEditViewContactPage.vue'),
  },
  {
    name: RouteNamesEnum.ViewContact,
    path: '/view-contact/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/AddressBook/pages/AddEditViewContactPage.vue'),
  },
  {
    name: RouteNamesEnum.MyFleet,
    path: '/my-fleet',
    meta: { requiresAuth: true },
    component: () => import('@/modules/MyFleet/pages/MyFleetPage.vue'),
  },
  {
    name: RouteNamesEnum.Users,
    path: '/users',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Users/pages/UsersPage.vue'),
  },
  {
    name: RouteNamesEnum.UsersView,
    path: '/user-view/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Users/pages/ViewUserPage.vue'),
  },
  {
    name: RouteNamesEnum.UsersEdit,
    path: '/user-edit/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Users/pages/AddEditUserPage.vue'),
  },
  {
    name: RouteNamesEnum.UsersAdd,
    path: '/user-add',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Users/pages/AddEditUserPage.vue'),
    props: {
      title: 'Add new'
    }
  },
  {
    name: RouteNamesEnum.AddVehicle,
    path: '/add-vehicle',
    meta: { requiresAuth: true },
    component: () => import('@/modules/MyFleet/pages/AddEditVehiclePage.vue'),
  },
  {
    name: RouteNamesEnum.EditVehicle,
    path: '/edit-vehicle/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/MyFleet/pages/AddEditVehiclePage.vue'),
  },
  {
    name: RouteNamesEnum.Accounting,
    path: '/accounting',
    meta: { requiresAuth: true },
    component: () =>
      import('@/modules/Accounting/pages/AccountingPage.vue'),
  },
  {
    name: RouteNamesEnum.AccountingDriver,
    path: '/accounting-driver',
    meta: { requiresAuth: true },
    component: () =>
      import('@/modules/Accounting/pages/AccountingCompaniesPage.vue'),
  },
  {
    name: RouteNamesEnum.AccountingPoster,
    path: '/accounting-poster',
    meta: { requiresAuth: true },
    component: () =>
      import('@/modules/Accounting/pages/AccountingCompaniesPage.vue'),
  },
  {
    name: RouteNamesEnum.AccountingInvoicesDriver,
    path: '/accounting-invoices-driver/:id',
    meta: { requiresAuth: true },
    component: () =>
      import('@/modules/General/pages/ListInvoicesPage.vue'),
  },
  {
    name: RouteNamesEnum.AccountingInvoicesPoster,
    path: '/accounting-invoices-poster/:id',
    meta: { requiresAuth: true },
    component: () =>
      import('@/modules/General/pages/ListInvoicesPage.vue'),
  },
  {
    name: RouteNamesEnum.ViewInvoice,
    path: '/view-invoice/:id',
    meta: { requiresAuth: true },
    component: () =>
      import('@/modules/General/pages/ViewInvoicePage.vue'),
  },
  {
    name: RouteNamesEnum.Documents,
    path: '/documents',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Documents/pages/DocumentsPage.vue'),
  },
  {
    name: RouteNamesEnum.DocumentList,
    path: '/documents-list/:type',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('@/modules/Documents/pages/DocumentListPage.vue'),
  },
  {
    name: RouteNamesEnum.AddViewDocument,
    path: '/add-view-document',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('@/modules/Documents/pages/AddViewDocument.vue'),
  },
  {
    name: RouteNamesEnum.EmptyRouterView,
    path: '/directory',
    meta: { requiresAuth: true },
    component: () => import('@/modules/General/components/EmptyRouterView.vue'),
    children: [
      {
        name: RouteNamesEnum.Directory,
        path: '',
        meta: { requiresAuth: true },
        component: () => import('@/modules/Directory/pages/DirectoryPage.vue'),
      },
      {
        name: RouteNamesEnum.DirectoryTeam,
        path: ':id',
        meta: { requiresAuth: true },
        component: () => import('@/modules/Directory/pages/DirectoryPage.vue'),
      },
      {
        name: RouteNamesEnum.DirectoryDetails,
        path: 'directory-details/:id',
        meta: { requiresAuth: true },
        component: () =>
          import('@/modules/Directory/pages/DirectoryDetailsPage.vue'),
      },
    ],
  },
  {
    name: RouteNamesEnum.Locations,
    path: '/locations',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Locations/pages/LocationsPage.vue'),
  },
  {
    name: RouteNamesEnum.LocationAdd,
    path: '/location-add',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Locations/pages/AddEditLocationPage.vue'),
    props: {
      title: 'Add new'
    }
  },
  {
    name: RouteNamesEnum.LocationEdit,
    path: '/location-edit/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Locations/pages/AddEditLocationPage.vue'),
  },
  {
    name: RouteNamesEnum.MyWork,
    path: '/my-work',
    meta: { requiresAuth: true },
    component: () => import('@/modules/MyWork/pages/MyWorkPage.vue'),
  },
  {
    name: RouteNamesEnum.OpenWork,
    path: '/my-work/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/JobsPosted/pages/OpenJobPage.vue'),
  },
  {
    name: RouteNamesEnum.Messages,
    path: '/messages',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Messages/pages/MessagesPage.vue'),
  },
  {
    name: RouteNamesEnum.Chat,
    path: '/chat/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Messages/pages/ChatPage.vue'),
  },
  {
    name: RouteNamesEnum.BrowseJobs,
    path: '/browse-jobs',
    meta: { requiresAuth: true },
    component: () => import('@/modules/BrowseJobs/pages/BrowseJobsPage.vue'),
  },
  {
    name: RouteNamesEnum.OpenBrowseJob,
    path: '/browse-jobs/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/JobsPosted/pages/OpenJobPage.vue'),
  },
  {
    name: RouteNamesEnum.Settings,
    path: '/settings',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Settings/pages/SettingsPage.vue'),
  },
  {
    name: RouteNamesEnum.SettingsNotifications,
    path: '/job-notification-settings',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Settings/pages/SettingsNotificationsPage.vue'),
  },
  {
    name: RouteNamesEnum.SettingsProximityJobAlerts,
    path: '/job-proximity-alerts',
    meta: { requiresAuth: true },
  component: () => import('@/modules/Settings/pages/SettingsProximityJobAlertsPage.vue'),
  },
  {
    name: RouteNamesEnum.SettingsDemo,
    path: '/demo-account',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Settings/pages/SettingsDemoPage.vue'),
  },
  {
    name: RouteNamesEnum.SettingsApi,
    path: '/api-callbacks',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Settings/pages/SettingsApiPage.vue'),
  },
  {
    name: RouteNamesEnum.SettingsPassword,
    path: '/password',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Settings/pages/SettingsPasswordPage.vue'),
  },
  {
    name: RouteNamesEnum.Notifications,
    path: '/notifications',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Notifications/pages/NotificationsPage.vue'),
  },
  {
    name: RouteNamesEnum.JobsPosted,
    path: '/jobs-posted',
    meta: { requiresAuth: true },
    component: () => import('@/modules/JobsPosted/pages/JobsPostedPage.vue'),
  },
  {
    name: RouteNamesEnum.OpenJob,
    path: '/jobs-posted/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/JobsPosted/pages/OpenJobPage.vue'),
  },
  {
    name: RouteNamesEnum.UserFeedbacks,
    path: '/user-feedbacks/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Users/pages/FeedbackPage.vue'),
  },
  {
    name: RouteNamesEnum.JobFeedbacks,
    path: '/job-feedbacks/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/JobFeedbacksPage.vue'),
  },
  {
    name: RouteNamesEnum.PostJobChoose,
    path: '/post-job-choose',
    meta: { requiresAuth: true },
    component: () => import('@/modules/PostJob/pages/PostEditJobChoosePage.vue'),
  },
  {
    name: RouteNamesEnum.SavedLoads,
    path: '/saved-loads',
    meta: { requiresAuth: true },
    component: () => import('@/modules/SavedLoads/pages/SavedLoadsPage.vue'),
  },
  {
    name: RouteNamesEnum.CreateSavedLoadCreate,
    path: '/create-saved-load-create',
    meta: { requiresAuth: true },
    component: () => import('@/modules/PostJob/pages/PostEditJobCreatePage.vue'),
  },
  {
    name: RouteNamesEnum.CreateSavedLoadConfirm,
    path: '/create-saved-load-confirm',
    meta: { requiresAuth: true },
    component: () => import('@/modules/PostJob/pages/PostEditJobConfirmPage.vue'),
  },
  {
    name: RouteNamesEnum.CreateSavedLoadVehicle,
    path: '/create-saved-load-vehicle',
    meta: { requiresAuth: true },
    component: () => import('@/modules/PostJob/pages/PostEditJobVehiclePage.vue'),
  },
  {
    name: RouteNamesEnum.CreateSavedLoadAdditionalInfo,
    path: '/create-saved-load-additional-info',
    meta: { requiresAuth: true },
    component: () => import('@/modules/PostJob/pages/PostEditJobAdditionalInfoPage.vue'),
  },
  {
    name: RouteNamesEnum.EditSavedLoadCreate,
    path: '/edit-saved-load-create/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/PostJob/pages/PostEditJobCreatePage.vue'),
  },
  {
    name: RouteNamesEnum.EditSavedLoadConfirm,
    path: '/edit-saved-load-confirm/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/PostJob/pages/PostEditJobConfirmPage.vue'),
  },
  {
    name: RouteNamesEnum.EditSavedLoadVehicle,
    path: '/edit-saved-load-vehicle/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/PostJob/pages/PostEditJobVehiclePage.vue'),
  },
  {
    name: RouteNamesEnum.EditSavedLoadAdditionalInfo,
    path: '/edit-saved-load-additional-info/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/PostJob/pages/PostEditJobAdditionalInfoPage.vue'),
  },
  {
    name: RouteNamesEnum.PostJobCreate,
    path: '/post-job-create',
    meta: { requiresAuth: true },
    component: () => import('@/modules/PostJob/pages/PostEditJobCreatePage.vue'),
  },
  {
    name: RouteNamesEnum.PostJobConfirm,
    path: '/post-job-confirm',
    meta: { requiresAuth: true },
    component: () => import('@/modules/PostJob/pages/PostEditJobConfirmPage.vue'),
  },
  {
    name: RouteNamesEnum.PostJobVehicle,
    path: '/post-job-vehicle',
    meta: { requiresAuth: true },
    component: () => import('@/modules/PostJob/pages/PostEditJobVehiclePage.vue'),
  },
  {
    name: RouteNamesEnum.PostJobAdditionalInfo,
    path: '/post-job-additional-info',
    meta: { requiresAuth: true },
    component: () => import('@/modules/PostJob/pages/PostEditJobAdditionalInfoPage.vue'),
  },
  {
    name: RouteNamesEnum.EditJobCreate,
    path: '/edit-job-create/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/PostJob/pages/PostEditJobCreatePage.vue'),
  },
  {
    name: RouteNamesEnum.EditJobConfirm,
    path: '/edit-job-confirm/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/PostJob/pages/PostEditJobConfirmPage.vue'),
  },
  {
    name: RouteNamesEnum.EditJobVehicle,
    path: '/edit-job-vehicle/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/PostJob/pages/PostEditJobVehiclePage.vue'),
  },
  {
    name: RouteNamesEnum.EditJobAdditionalInfo,
    path: '/edit-job-additional-info/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/PostJob/pages/PostEditJobAdditionalInfoPage.vue'),
  },
  {
    name: RouteNamesEnum.EditStopsCreate,
    path: '/edit-stops-create/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/PostJob/pages/PostEditJobCreatePage.vue'),
  },
  {
    name: RouteNamesEnum.EditStopsConfirm,
    path: '/edit-stops-confirm/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/PostJob/pages/PostEditJobConfirmPage.vue'),
  },
  {
    name: RouteNamesEnum.Benefits,
    path: '/member-benefits',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Benefits/pages/BenefitsPage.vue'),
  },
  {
    name: RouteNamesEnum.Benefit,
    path: '/factoring/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Benefits/pages/BenefitPage.vue'),
  },
  {
    name: RouteNamesEnum.Help,
    path: '/help',
    component: () => import('@/modules/Help/pages/HelpPage.vue'),
  },
  {
    name: RouteNamesEnum.LeaveFeedback,
    path: '/leave-feedback/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/LeaveFeedbackPage.vue'),
  },
  {
    name: RouteNamesEnum.LiveAvailability,
    path: '/live-availability',
    meta: { requiresAuth: true },
    component: () => import('@/modules/LiveAvailability/pages/LiveAvailabilityPage.vue'),
  },
  {
    name: RouteNamesEnum.ViewDocument,
    path: '/view-document/:id?',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Documents/pages/ViewDocumentPage.vue'),
  },
  {
    name: RouteNamesEnum.UploadPod,
    path: '/upload-pod/:id?',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/UploadPodPage.vue'),
  },
  {
    name: RouteNamesEnum.UploadPor,
    path: '/upload-por/:id?',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/UploadPodPage.vue'),
  },
  {
    name: RouteNamesEnum.UploadElectronicPod,
    path: '/upload-electronic-pod/:id?',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/UploadElectronicPodPage.vue'),
  },
  {
    name: RouteNamesEnum.UploadStopPod,
    path: '/upload-stop-pod/:id?',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/UploadPodPage.vue'),
  },
  {
    name: RouteNamesEnum.ViewPod,
    path: '/view-pod/:id?',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/ViewCurrentJobStatusPage.vue'),
  },
  {
    name: RouteNamesEnum.ViewElectronicPod,
    path: '/view-electronic-pod/:id?',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/ViewCurrentJobStatusPage.vue'),
  },
  {
    name: RouteNamesEnum.ViewPor,
    path: '/view-por/:id?',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/ViewCurrentJobStatusPage.vue'),
  },
  {
    name: RouteNamesEnum.ViewStopPod,
    path: '/view-stop-pod/:id?',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/ViewCurrentJobStatusPage.vue'),
  },
  {
    name: RouteNamesEnum.ViewElectronicStopPod,
    path: '/view-electronic-stop-pod/:id?',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/ViewCurrentJobStatusPage.vue'),
  },
  {
    name: RouteNamesEnum.Bids,
    path: '/bids/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Bids/pages/BidsPage.vue'),
  },
  {
    name: RouteNamesEnum.TeamBids,
    path: '/team-bids/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Bids/pages/TeamBidsPage.vue'),
  },
  {
    name: RouteNamesEnum.SubmitBid,
    path: '/submit-bid/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Bids/pages/SubmitBidPage.vue'),
  },
  {
    name: RouteNamesEnum.UserBidsHistory,
    path: '/user-bids-history/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/Bids/pages/UserBidsHistoryPage.vue'),
  },
  {
    name: RouteNamesEnum.ViewGoodsOnBoard,
    path: '/view-goods-on-board/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/ViewCurrentJobStatusPage.vue'),
  },
  {
    name: RouteNamesEnum.ViewStopGoodsOnBoard,
    path: '/view-stop-goods-on-board/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/ViewCurrentJobStatusPage.vue'),
  },
  {
    name: RouteNamesEnum.OnRouteToCollect,
    path: '/on-route-to-collect/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/OnRouteToCollectPage.vue'),
  },
  {
    name: RouteNamesEnum.ViewOnRouteToCollect,
    path: '/view-on-route-to-collect/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/ViewCurrentJobStatusPage.vue'),
  },
  {
    name: RouteNamesEnum.ArrivedAtCollection,
    path: '/arrived-at-collection/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/ArrivedAtCollectionPage.vue'),
  },
  {
    name: RouteNamesEnum.ViewArrivedAtCollection,
    path: '/view-arrived-at-collection/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/ViewCurrentJobStatusPage.vue'),
  },
  {
    name: RouteNamesEnum.OnRouteToReturn,
    path: '/on-route-to-return/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/OnRouteToDeliverPage.vue'),
  },
  {
    name: RouteNamesEnum.ViewOnRouteToReturn,
    path: '/view-on-route-to-return/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/ViewCurrentJobStatusPage.vue'),
  },
  {
    name: RouteNamesEnum.OnRouteToDeliver,
    path: '/on-route-to-deliver/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/OnRouteToDeliverPage.vue'),
  },
  {
    name: RouteNamesEnum.ViewOnRouteToDeliver,
    path: '/view-on-route-to-deliver/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/ViewCurrentJobStatusPage.vue'),
  },
  {
    name: RouteNamesEnum.ArrivedAtDestination,
    path: '/arrived-at-destination/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/ArrivedAtDestinationPage.vue'),
  },
  {
    name: RouteNamesEnum.ViewArrivedAtDestination,
    path: '/view-arrived-at-destination/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/ViewCurrentJobStatusPage.vue'),
  },
  {
    name: RouteNamesEnum.AddGoodsOnBoard,
    path: '/add-goods-on-board/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/AddGoodsOnBoardPage.vue'),
  },
  {
    name: RouteNamesEnum.AddStopGoodsOnBoard,
    path: '/add-stop-goods-on-board/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/AddGoodsOnBoardPage.vue'),
  },
  {
    name: RouteNamesEnum.RaiseInvoice,
    path: '/raise-invoice/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/FlowForJob/pages/RaiseInvoicePage.vue'),
  },
  {
    name: RouteNamesEnum.PreviewInvoice,
    path: '/preview-invoice/:id',
    meta: { requiresAuth: true },
    component: () => import('@/modules/General/pages/ViewInvoicePage.vue'),
  },
  {
    name: RouteNamesEnum.ListInvoices,
    path: '/list-invoices/:id',
    meta: { requiresAuth: true },
    component: () =>
      import('@/modules/General/pages/ListInvoicesPage.vue'),
  },
  {
    name: RouteNamesEnum.Logout,
    path: '/logout',
    component: {},
  },
  {
    name: RouteNamesEnum.Impersonate,
    path: '/impersonate/:token',
    component: () => import('@/modules/Auth/pages/ImpersonatePage.vue'),
  },
  {
    name: RouteNamesEnum.OldAndroidApp,
    path: '/authenticate',
    component: () => import('@/modules/General/pages/OldAndroidApp.vue'),
  },
  {
    name: RouteNamesEnum.Maintenance,
    path: '/maintenance',
    component: () => import('@/modules/General/pages/MaintenancePage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

StorageService.setItem('isAlreadySubscribed', 'false');

let user: User = StorageService.getUser();

if (typeof window !== 'undefined' && user) {
  window.addEventListener('beforeunload', async function (event: BeforeUnloadEvent) {
    // await clearUpdatePosition(user.id)
  });
}

if (!isMobile.value) {
  setupStripe();
}

const isRunIntervalForSystemPermissionCheckStarted = ref(false)

router.beforeEach(async (to, from, next) => {
  user = StorageService.getUser();
  if(to.name !== RouteNamesEnum.OldAndroidApp && !isRunIntervalForSystemPermissionCheckStarted.value) {
    runIntervalForSystemPermissionCheck();
    isRunIntervalForSystemPermissionCheckStarted.value = true;
  }

  if (!user && to.meta.requiresAuth) {
    next('/login');
    return;
  } else if (user) {
    subscribeWithInterval(user);
    setFirebaseUserOnline(user, true)
    
    const isPostJobPage = to.name === RouteNamesEnum.PostJobCreate || to.name === RouteNamesEnum.PostJobConfirm || to.name === RouteNamesEnum.PostJobVehicle || to.name === RouteNamesEnum.PostJobAdditionalInfo

    if(user?.is_impersonated || (user?.team?.is_active_subscriber && (user?.team?.payment_method === PaymentMethod.DirectDebit || user?.team?.payment_method === PaymentMethod.Bacs)) ? false : (!user?.team?.is_active_subscriber || (!user?.team?.plan?.verified && isPlatform('ios') && user?.team?.subscription_status !== SubscriptionStatuses.Trial)) && to.meta.requiresAuth) {
      next('/company-email')
      return;
    }

    if(!isAbleToDo(user, ProfileSettingsEnum.PostWork) && isPostJobPage) {
      next('/notifications')
      return;
    }
    if (!user.inactivated) {
      if ((isAbleToDo(user, ProfileSettingsEnum.Tracking) || isAbleToDo(user, ProfileSettingsEnum.JobNotificationTracking)) && !StorageService.getItem('isPositionWatching') && to.meta.requiresAuth) {
        let result;
        if(!window.history.state.back) {
          result = await checkLocationPermissions(false)
        } else {
          result = await checkLocationPermissions(true)
        }
        if (result === 'granted') {
          handleFirebaseUserTracking(user);
        }
      }
    }

    if (to.name === RouteNamesEnum.Logout) {
      deactivateFirebaseUser(user);
      unsubscribeFromAllSubscriptions();
      clearUpdatePosition(user);
      setFirebaseUserOnline(user, false);
      isRunIntervalForSystemPermissionCheckStarted.value = false;
      if(isMobile.value) {
        OneSignal.setExternalId('');
      }

      initializeApollo().mutate({ mutation: Logout });
      if(isMobile.value) {
        disableListeners();
      }
      
      StorageService.clearAll();
      StorageService.setItem('isAlreadySubscribed', 'false');
      next('/login');
      return;
    }

    if (user.inactivated && to.name !== RouteNamesEnum.Attention && !user?.is_impersonated) {
      next({
        path: '/attention',
        query: { type: 'user' }
      })
      return;
    }

    if (!user.team) {
      if ((user.registration_progress === RegistrationProgress.CompanyInfo && to.meta.requiresAuth) || !to.meta.requiresAuth) {
        next();
        return;
      } else if (user.registration_progress !== RegistrationProgress.CompanyInfo && to.meta.requiresAuth) {
        next('/login');
        return;
      }
    }

    next();
  } else {
    next();
  }
})
export default router;